import MapManager from "./components/MapManager";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import REST from "../../tools/REST"
import WSClient from '@pascom/websocket/lib/client';
// import { getURL } from "../../functions";
import Loader from "../components/Loader";
import REST from "../utils/rest";
import MDIcon from "./components/icon";
import { useTranslation } from "react-i18next";
import { useAuth } from "../contexts/AuthContext";
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
// import Loader from '@material-ui/core/CircularProgress';


const mm = new MapManager({ RECORD_LAST_AREAS: true, RECORD_LAST_POSITIONS: true, HEATMAP: true })
window.mm = mm;

const WS_URL = `${REST.URL().replace('http', 'ws')}rtls`;
const wsc = new WSClient({
  serverUrl: WS_URL,
  reloadOnServerChange: true, // true = Při restartu serveru se provede reload
  clientId: 1
});

const wsSend = (data) => wsc.ws.send(JSON.stringify(data))

export default function MapPage() {

  const [data, setData] = useState(null);
  const [filter1, setFilter1] = useState(null);
  const [filter2, setFilter2] = useState(`gap`);
  const [draggable, setDraggable] = useState(false);
  const { t, i18n } = useTranslation()
  const { currentUser } = useAuth()
  const [month, setMonth] = useState(new Date().getTime())


  //options for months with years from past 2 years in format `MMMM yyyy`
  const month_options = useMemo(() => {
    let options = []
    let date = new Date()
    for (let i = 0; i < 48; i++) {
      options.push({ title: date.toLocaleDateString(currentUser?.UserLangISOCode || `en`, { month: `long`, year: `numeric` }), value: date.getTime() })
      date.setMonth(date.getMonth() - 1)
    }
    return [...options]
  }, [])


  const loader = useRef(null)

  const getData = useCallback(async () => {
    loading(true)

    let filters = { filter1, month, /* filter2 */ }
    let query_params = Object.keys(filters).filter(key => filters[key] !== null).map(key => `${key}=${filters[key]}`).join('&')
    const [success, result] = await REST.GET(`rtls?${query_params}`);

    loading(false)

    if (!success) return;

    setData(result)

    mm.refreshAllFeatures(result?.features)
    // result?.features?.forEach((feature) => {
    //   mm.featureChanged(feature)
    // })
  }, [filter1, month]);

  const loading = (val) => {
    if (val) {
      loader?.current?.classList.add('loading')
    } else {
      loader?.current?.classList.remove('loading')
    }
  }

  // mm.loading = loading;

  useEffect(() => {
    getData()

    const interval = setInterval(() => getData(), 30000)

    const featureChanged = ({ detail }) => mm.featureChanged(detail)
    const showFailMessage = () => { }

    wsc.on('feature_update', featureChanged); // Zobrazí zprávu

    wsc.on('error', showFailMessage); // Zobrazí chybovou hlášku o přerušeném spojení
    wsc.on('close', showFailMessage); // Zobrazí chybovou hlášku o přerušeném spojení
    wsc.on('connect', () => {

    });

    return () => {
      wsc.off('feature_update', featureChanged);
      clearInterval(interval)
    };
  }, [filter1, month])

  useEffect(() => {
    if (filter2) {
      let show = data?.filter2_options?.map(layer => layer?.title)
      let hide = [] //data?.filter2_options?.filter(option => option?.value !== filter2)?.map(layer => layer?.title)

      if (filter2 === `gap`) {
        show = data?.filter2_options?.filter(option => option?.value === `gap`)?.map(layer => layer?.title)
        hide = data?.filter2_options?.filter(option => option?.value !== `gap`)?.map(layer => layer?.title)
      }

      if (mm?.setLayers)
        mm?.setLayers({ action: `bulk_set`, show, hide })
    }
  }, [data, filter2])

  useEffect(() => {
    mm.setDraggable(draggable)
  }, [draggable])

  const handleLogoClick = () => {
    history.push('/');
    window.location.reload();
  };

  if (!data?.mapSource)
    return <div style={{ position: `absolute`, top: `50%`, left: `50%`, transform: `translate(-50%, -50%)` }}> <Loader /></div>

  console.log({ data })

  return <>
    <mm.Map
      imageSource={`${REST.URL()}${data?.mapSource}`}
      features={data?.features}
      geofences={data?.geofences}
      recordHistory
      draggableFeatures={draggable}
      onFeatureDragEnd={(feature) => { wsSend({ command: `feature_moved_manually`, feature: { ...feature, controler: null } }) }}
    />
    <Box
      component={Link}
      to="/"
      onClick={handleLogoClick}
      sx={{
        display: 'inline-flex',
        height: 50,
        width: "auto",
        marginTop: 1,
        marginLeft: 1,
        position: 'absolute',
        zIndex: 10
      }}
    >
      <img
        src={"/assets/logo/eduway_logo.png"}
        alt="Eduway Logo"
        style={{ height: '50px', width: 'auto' }}
      />
    </Box>
    <div className={`refresh`} ref={loader}>
      <MDIcon className={`refresh-icon`} path={`mdiRefresh`} onClick={() => getData()} />
    </div>
    {data?.AUTHORIZED_EDIT && <MDIcon className={`draggable-icon ${draggable ? `dragging` : ``}`} path={`mdiArrowAll`} onClick={() => setDraggable(!draggable)} />}
    <div className="filters">
      {month_options && <select onChange={(e) => setMonth(e.target.value)}>
        {month_options.map((option, i) => <option key={i} value={option?.value}>{option?.title}</option>)}
      </select>}
      {data?.filter1_options && <select onChange={(e) => setFilter1(e.target.value)}>
        {data.filter1_options.map((option, i) => <option key={i} value={option?.value}>{option?.title}</option>)}
      </select>}
      {data?.filter2_options && <select onChange={(e) => setFilter2(e.target.value)}>
        {data.filter2_options.map((option, i) => <option key={i} value={option?.value}>{option?.title}</option>)}
      </select>}
    </div >
  </>
}
